@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Cera Pro";
  src: url("../public/fonts/cera-pro-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../public/fonts/cera-pro-bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../public/fonts/cera-pro-light.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../public/fonts/cera-pro-italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../public/fonts/cera-pro-bold-italic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("../public/fonts/cera-pro-light-italic.otf") format("opentype");
  font-weight: lighter;
  font-style: italic;
}

@layer base {
  html {
    font-family: "Cera Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10.5pt;
    line-height: 1.5;
  }
  h2, h3, h4 {
    text-transform: uppercase;
  }
  h2 {
    font-size: 27pt;
    font-weight: bold;
  }
  h3 {
    font-size: 17pt;
    font-weight: bold;
  }
  h4 {
    font-size: 16pt;
    font-weight: bold;
  }
  h5 {
    font-size: 11pt;
    font-weight: bold;
  }
  p {
    font-size: 10.5pt;
    font-weight: normal;
  } 
}
